<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 sm4>
      <v-card class="mb-2">
        <title-card :title="'Fotos publicadas'"></title-card>
      </v-card>
      <v-layout v-if="checks.length > 0" wrap>
        <v-flex v-for="(check, index) in checks" :key="index" xs12 sm12 lg6 xl4>
          <v-card style="width: 100%; margin: 0 auto" class="mb-2">
            <v-img :src="check.photo" aspect-ratio="1" @click="openImg(check.photo)"></v-img>
            <v-card-actions>
              <v-btn block ripple flat @click="publish(check.id_detail, true)">
                Quitar <v-icon right color="error lighten-1"> close </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 sm8>
      <v-card>
        <title-card
          :title="'Listado de fotos disponibles'"
          :subtitle="'RECUERDE NO PUBLICAR FOTOS CON PERSONAS'"
        ></title-card>
        <v-divider light></v-divider>
        <v-data-table :headers="headers" :items="items" class="elevation-0" no-data-text="No hay fotos disponibles">
          <template slot="items" slot-scope="props">
            <td class="pa-2">
              <v-img
                :src="props.item.photo"
                :lazy-src="`https://picsum.photos/10/6`"
                class="grey lighten-2"
                width="200"
                @click.native="openImg(props.item.photo)"
              />
            </td>
            <td class="text-xs-right">{{ props.item.id }}</td>
            <td class="text-xs-center">
              <v-icon v-if="props.item.encuesta" color="success"> check </v-icon>
              <v-icon v-else color="error"> close </v-icon>
            </td>
            <td class="text-xs-right">
              <v-btn
                v-if="!props.item.publish"
                :loading="loadingBtn"
                :disabled="loadingBtn"
                class="mt-0"
                color="info"
                depressed
                small
                @click="publish(props.item.id_detail, props.item.publish)"
              >
                Publicar
              </v-btn>
              <v-btn
                v-else
                :loading="loadingBtn"
                :disabled="loadingBtn"
                class="mt-0"
                color="error"
                depressed
                small
                @click="publish(props.item.id_detail, props.item.publish)"
              >
                Quitar
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialogPhoto" max-width="400">
      <v-card class="pb-0" style="margin-bottom: -6px; overflow: hidden">
        <img :src="this.photoLarge" width="400" />
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import qs from 'qs'
import titleCard from '../useful/titleCard.vue'
import { GET_API, GET_PRODUCT } from '@/config'

export default {
  components: {
    titleCard
  },
  props: ['product'],
  data() {
    return {
      items: [],
      checks: [],
      photoLarge: null,
      dialogPhoto: false,
      loadingBtn: false,
      headers: [
        {
          text: 'Foto de entrega',
          align: 'left',
          sortable: false,
          value: 'photo'
        },
        {
          text: 'Pedido',
          align: 'right',
          sortable: false,
          value: 'uid'
        },
        {
          text: 'Encuesta',
          align: 'center',
          sortable: false,
          value: 'uid'
        },
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'uid'
        }
      ]
    }
  },
  watch: {
    product() {
      this.getProductsDelivery()
    }
  },
  mounted() {
    this.getProductsDelivery()
  },
  methods: {
    async publish(id, publish) {
      this.loadingBtn = true
      const index = this._.findIndex(this.items, ['id_detail', id])
      try {
        await this.$http.put(`${GET_PRODUCT}/${this.product.id}/delivery/${id}`, {
          publish: !publish
        })
        this.getChecksProducts()
        setTimeout(() => {
          this.loadingBtn = false
          if (this.items[index] !== undefined) {
            this.items[index].publish = !publish
          }
        }, 800)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getProductsDelivery() {
      if (this.product.id !== undefined) {
        try {
          const res = await this.$http.get(`${GET_PRODUCT}/${this.product.id}/delivery`, {
            id: this.product.id
          })
          this.items = res.data.shippings
          this.checks = res.data.checks
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    async getChecksProducts() {
      try {
        const res = await this.$http.get(`${GET_PRODUCT}/${this.product.id}/delivery-check`)
        this.checks = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    openImg(img) {
      this.dialogPhoto = true
      this.photoLarge = img
    }
  }
}
</script>
